.modal
  display: none
  cursor: pointer
  &:not(:empty)
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    display: flex
    align-items: center
    justify-content: center
    z-index: 10000
    background-color: var(--overlay)
  &__wrap
    position: relative
    width: 360px
    margin: 10px
    padding: 10px
    background-color: #fff
    border-radius: 10px
    box-shadow: var(--shadow) 0px 14px 28px, var(--shadow) 0px 10px 10px
    animation: modalShow .5s var(--easeOutQuart)
  &__content
    flex: 1
    overflow-y: auto
    height: 100%
    text-align: center
    margin: 50px 0
    transform: translateZ(0)
    white-space: pre-line
  &__close
    composes: c-btn-main from global
    display: block
    margin-top: 10px
  &__icon
    position: absolute
    right: 10px
    top: 10px
    border: 0
    padding: 0
    margin: 0
    line-height: 1
    font-size: 2.8rem
    font-weight: bold
    background-color: transparent
    cursor: pointer
    &:hover
      opacity: .8

@keyframes modalShow
  0%
    opacity: 0
    transform: translateY(10px)
  100%
    opacity: 1
    transform: translateY(0)
