.conversion
  display: none
  cursor: pointer
  &:not(:empty)
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    display: flex
    z-index: 10000
    background-color: var(--overlay)
  &__inner
    position: relative
    padding: 30px
    background-color: var(--white)
    width: min(800px, 90vw)
  &__arrows
    position: absolute
    top: 100px
    right: calc(-3rem - 10px)
    display: flex
    flex-direction: column
    background-color: var(--white)
    border-radius: 2px
    z-index: 1
  &__arrow
    composes: u-link from global
    padding: 10px
    font-size: 3rem
    line-height: 1
    transition: all .3s ease
    &[data-disabled='true']
      pointer-events: none
      color: var(--gray)
